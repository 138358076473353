<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="sale_price">
                    {{ row.sale_price | cny }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '关闭', 1: '启用' }[row.status] }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqDiscountCodeList, reqDiscountCodeDel } from '@/lib/request/auth2';
import { isNotNullObject } from '@/lib/util';

export default {
    name: 'discountCodeList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'title' }],
            dataColumn: [
                { key: 'id', title: 'ID', minWidth: 80 },
                { key: 'title', title: '名称', minWidth: 150 },
                { key: 'address', title: '地址', minWidth: 200, tooltip: true },
                { slot: 'sale_price', title: '优惠金额', minWidth: 100 },
                { key: 'couponCode', title: '优惠码', minWidth: 100 },
                { key: 'showTime', title: '有效时间', minWidth: 250 },
                { slot: 'status', title: '状态', minWidth: 80 },
                { slot: 'manager', title: '状态', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            reqDiscountCodeList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_info = {}) {
            let info = window.structuredClone(_info);
            if (isNotNullObject(info)) {
                info.address_code = [info.sheng || '', info.shi || '', info.qu || ''];
                info.address_note = (info.address.split(';') || ['', ''])[1];
            } else {
                info.create = 1;
            }
            this.goName('discountCodeAdd', info);
        },
        //删除
        onDelete(info = {}) {
            let { id, title } = info || {};
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDiscountCodeDel({ id })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(res => {
                            this.fadeWarning(res.errmsg);
                            this.hideAjaxLoading();
                        });
                }
            });
        },
    },
};
</script>
